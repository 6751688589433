import {Pipe, PipeTransform} from '@angular/core';

const FIRST_DASH_POSITION = 3;
const SECOND_DASH_POSITION = 5;

@Pipe({name: 'ssnFormat'})
export class SsnFormatPipe implements PipeTransform {
    transform(input: string): string {
        return SsnFormatPipe.transform(input).formatted;
    }

    static transform(value: string): {formatted: string; unFormatted: string} {
        if (!value) return {formatted: '', unFormatted: ''};

        const unFormatted = value.replace(/-/g, '');
        let formatted = unFormatted.substring(0, FIRST_DASH_POSITION);

        if (unFormatted.length > FIRST_DASH_POSITION) {
            formatted = formatted.concat(`-${unFormatted.substring(FIRST_DASH_POSITION, SECOND_DASH_POSITION)}`);

            if (unFormatted.length > SECOND_DASH_POSITION) {
                formatted = formatted.concat(`-${unFormatted.substring(SECOND_DASH_POSITION)}`);
            }
        }

        return {formatted, unFormatted};
    }
}
